import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../_services/authservice';

export const LoginGuard: CanActivateFn = (route, state) => {

  const router = inject(Router);
  const authservice = inject(AuthService);

  if (!authservice.isUserLogedIn())
    return true;

  if (authservice.isUserLogedIn())
    router.navigate(["/"])
 

  return false;
};

