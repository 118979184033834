import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface AppConfig {
    inputStyle: string;
    colorScheme: string;
    theme: string;
    ripple: boolean;
    menuMode: string;
    scale: number;
}

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {

    config: AppConfig = {
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: 'lara-light-indigo',
        scale: 14,
    };

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false
    };
    openSideMenu = false;


    private tabTitle = new Subject<any>();
    getTabTitle = this.tabTitle.asObservable();
    BCtabTitle?: string;
    BCtabTitleObject: any;

    private newMsgValue = new Subject<any>();
    getnewMsgValue = this.newMsgValue.asObservable();


    private configUpdate = new Subject<AppConfig>();

    private overlayOpen = new Subject<any>();

    configUpdate$ = this.configUpdate.asObservable();

    overlayOpen$ = this.overlayOpen.asObservable();

    newMsg(newMsgData: any) {
        this.newMsgValue.next(newMsgData);
    }

    setBCTitle(BCTitle: string, loading:any) {
        if (!loading) {
            loading = false
        }
        this.BCtabTitle = this.translate.instant(BCTitle);
        this.BCtabTitleObject = { title: this.translate.instant(BCTitle), loading: loading };
        this.tabTitle.next({ title: this.translate.instant(BCTitle), loading: loading });
        this.useSpinnerSource.next(loading);

    }

    Loading(loading:any) {
        this.BCtabTitleObject = { title: this.BCtabTitle, loading: loading };
        this.tabTitle.next({ title: this.BCtabTitle, loading: loading });
    }
    onMenuToggle() {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;
            if (this.state.overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }

        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
        }
        else {
            this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    showProfileSidebar() {
        this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
        if (this.state.profileSidebarVisible) {
            this.overlayOpen.next(null);
        }
    }

    showConfigSidebar() {
        this.state.configSidebarVisible = true;
    }

    isOverlay() {
        return this.config.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return !this.isDesktop();
    }

    onConfigUpdate() {
        this.configUpdate.next(this.config);
    }


    _lang = localStorage.getItem('lang') || 'ar';
    private langSource = new BehaviorSubject(this._lang);

    currentLang = this.langSource.asObservable();

    constructor(private translate: TranslateService) {
        this.changeLang(this.readLastLang());
    }

    changeLang(lang: any) {
        this.langSource.next(lang)
    }

    readLastLang = (): string => {
        try {
            let l = JSON.parse(localStorage.getItem('lang')!);
            if (l != undefined || l != null) {
                return l;
            }

        } catch {
            return this._lang
        }
        return this._lang
    }

    private currentLanguage = new Subject<string>();
  
    changeLocal(local: string) {
      this.translate.use(local);
      this.currentLanguage.next(local);
    }
  
    getLocalEvent():Observable<string> {
      return this.currentLanguage as Observable<string>
    }
  
    changeContentAndLayout()
    {
      this.translate.setDefaultLang(localStorage.getItem('lang') || 'ar');
      this.translate.use(localStorage.getItem('lang') || 'ar');
      let subscription = this.getLocalEvent()
      .subscribe(local => this.translate.use(local))
  
    }

    _useSpinner = false;
    private useSpinnerSource = new BehaviorSubject(this._useSpinner);
    useSpinner = this.useSpinnerSource.asObservable();

    useSpiner(useSpiner:boolean)
    {
        this.useSpinnerSource.next(useSpiner);
    }

    _showSpinner = false;
    private SpinnerSource = new BehaviorSubject(this._showSpinner);
    Spinner = this.SpinnerSource.asObservable();

    showSpinner()
    {
        this.SpinnerSource.next(true);
    }

    HideSpinner()
    {
        this.SpinnerSource.next(false);
    }
}
