import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../_services/authservice';

export const AuthGuard: CanActivateFn = (route, state) => {

  const router = inject(Router);
  const authservice = inject(AuthService);

  if (!authservice.isUserLogedIn())
    router.navigate(["/login"])

  if (authservice.isUserLogedIn() && state.url=="/login")
    router.navigate(["/dashboard"])

  if (authservice.isUserLogedIn())
       return true;

  if (authservice.isSuperAdmin())
    return true;

  if (authservice.hasPermission(state.url))
    return true;

  if (authservice.isNotHasPermission(state.url))
    router.navigate(["/access-denied"])

  if (authservice.isEditOrList(state.url))
    return true;

  if (authservice.hasAddPermission(state.url))
    router.navigate([state.url.replace("list", "add")])


  return false;
};

