import { Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { LoginGuard } from './_helpers/login.guard';

export const appRoutes = {
  login: "dashboard/Login",
  home: "dashboard/Home",
  notFound: "dashboard/NotFound",


  listRole:"dashboard/RolesManagement",
  addRole:"dashboard/AddRole",
  editRole:"dashboard/EditRole",

  listUser:"dashboard/UsersManagement",
  addUser:"dashboard/AddUser",
  editUser:"dashboard/EditUser",
  
  listReference:"dashboard/ReferenceManagement",
  addReference:"dashboard/AddReference",
  editReference:'dashboard/EditReference',

    
  listPosition:"dashboard/PositionManagement",
  addPosition:"dashboard/AddPosition",
  editPosition:'dashboard/EditPosition',


  listCategory:"dashboard/CategoriesManagement",
  addCategory:"dashboard/AddCategory",
  editCategory:'dashboard/EditCategory',

  listBrand:"dashboard/BrandsManagement",
  addBrand:"dashboard/AddBrand",
  editBrand:'dashboard/EditBrand',

  listProduct:"dashboard/ProductsManagement",
  addProduct:"dashboard/AddProduct",
  editProduct:"dashboard/EditProduct",
  
  listTag:"dashboard/TagsManagement",
  addTag:"dashboard/AddTag",
  editTag:"dashboard/EditTag",
  
  listOrder:"dashboard/OrdersManagement",
  addOrder:"dashboard/AddOrder",
  editOrder:"dashboard/EditOrder",

  listStudent:"dashboard/StudentsManagement",
  addStudent:"dashboard/AddStudent",
  editStudent:"dashboard/EditStudent",

  listTeacher:"dashboard/TeachersManagement",
  addTeacher:"dashboard/AddTeacher",
  editTeacher:"dashboard/EditTeacher",

  listEmployee:"dashboard/EmployeesManagement",
  addEmployee:"dashboard/AddEmployee",
  editEmployee:"dashboard/EditEmployee",

  



};

export const routes: Routes = [

{path:"",redirectTo:'dashboard',pathMatch:'full',},



{path:"login",loadComponent: () => import('./auth/login/login.component').then(c=>c.LoginComponent),canActivate:[LoginGuard]},

{ 
  path:"dashboard",loadComponent: () => import('./dashboard/dashboard/dashboard.component').then(c => c.DashboardComponent),canActivate:[AuthGuard],
  children:[
        {path:'',loadComponent: () => import('./dashboard/home/home/home.component').then(c => c.HomeComponent)},

        {path:"RolesManagement",loadComponent: () => import('./dashboard/system/role/role-list/role-list.component').then(c => c.RoleListComponent),canActivate:[AuthGuard]},
        {path: "AddRole", loadComponent: () => import('./dashboard/system/role/role-add/role-add.component').then(c => c.RoleAddComponent) ,canActivate:[AuthGuard]},
        {path:"EditRole/:id",loadComponent: () => import('./dashboard/system/role/role-edit/role-edit.component').then(c => c.RoleEditComponent),canActivate:[AuthGuard]},

        {path:"UsersManagement",loadComponent: () => import('./dashboard/system/user/user-list/user-list.component').then(c => c.UserListComponent),canActivate:[AuthGuard]},
        {path: "AddUser", loadComponent: () => import('./dashboard/system/user/user-add/user-add.component').then(c => c.UserAddComponent) ,canActivate:[AuthGuard]},
        {path:"EditUser/:id",loadComponent: () => import('./dashboard/system/user/user-edit/user-edit.component').then(c => c.UserEditComponent),canActivate:[AuthGuard]},
  
 
        {path:"BrandsManagement",loadComponent: () => import('./dashboard/store/brand/brand-list/brand-list.component').then(c => c.BrandListComponent),canActivate:[AuthGuard]},
        {path:"AddBrand",loadComponent: () => import('./dashboard/store/brand/brand-add/brand-add.component').then(c => c.BrandAddComponent),canActivate:[AuthGuard]},
        {path:'EditBrand/:id',loadComponent: () => import('./dashboard/store/brand/brand-edit/brand-edit.component').then(c => c.BrandEditComponent),canActivate:[AuthGuard]},


        {path:"ReferenceManagement",loadComponent: () => import('./dashboard/structure/reference/reference-list/reference-list.component').then(c => c.ReferenceListComponent),canActivate:[AuthGuard]},
        {path:"AddReference",loadComponent: () => import('./dashboard/structure/reference/reference-add/reference-add.component').then(c => c.ReferenceAddComponent),canActivate:[AuthGuard]},
        {path:'EditReference/:id',loadComponent: () => import('./dashboard/structure/reference/reference-edit/reference-edit.component').then(c => c.ReferenceEditComponent),canActivate:[AuthGuard]},

        {path:"PositionManagement",loadComponent: () => import('./dashboard/structure/position/position-list/position-list.component').then(c => c.PositionListComponent),canActivate:[AuthGuard]},
        {path:"AddPosition",loadComponent: () => import('./dashboard/structure/position/position-add/position-add.component').then(c => c.PositionAddComponent),canActivate:[AuthGuard]},
        {path:'EditPosition/:id',loadComponent: () => import('./dashboard/structure/position/position-edit/position-edit.component').then(c => c.PositionEditComponent),canActivate:[AuthGuard]},


        {path:"StudentsManagement",loadComponent: () => import('./dashboard/student-affairs/student/student-list/student-list.component').then(c => c.StudentListComponent),canActivate:[AuthGuard]},
        {path:"AddStudent",loadComponent: () => import('./dashboard/student-affairs/student/student-add/student-add.component').then(c => c.StudentAddComponent),canActivate:[AuthGuard]},
        {path:'EditStudent/:id',loadComponent: () => import('./dashboard/student-affairs/student/student-edit/student-edit.component').then(c => c.StudentEditComponent),canActivate:[AuthGuard]},


        {path:"TeachersManagement",loadComponent: () => import('./dashboard/administrative-affairs/teacher/teacher-list/teacher-list.component').then(c => c.TeacherListComponent),canActivate:[AuthGuard]},
        {path:"AddTeacher",loadComponent: () => import('./dashboard/administrative-affairs/teacher/teacher-add/teacher-add.component').then(c => c.TeacherAddComponent),canActivate:[AuthGuard]},
        {path:'EditTeacher/:id',loadComponent: () => import('./dashboard/administrative-affairs/teacher/teacher-edit/teacher-edit.component').then(c => c.TeacherEditComponent),canActivate:[AuthGuard]},


        {path:"EmployeesManagement",loadComponent: () => import('./dashboard/administrative-affairs/employee/employee-list/employee-list.component').then(c => c.EmployeeListComponent),canActivate:[AuthGuard]},
        {path:"AddEmployee",loadComponent: () => import('./dashboard/administrative-affairs/employee/employee-add/employee-add.component').then(c => c.EmployeeAddComponent),canActivate:[AuthGuard]},
        {path:'EditEmployee/:id',loadComponent: () => import('./dashboard/administrative-affairs/employee/employee-edit/employee-edit.component').then(c => c.EmployeeEditComponent),canActivate:[AuthGuard]},

  ]
},



{path:'access-denied',loadComponent: () => import('./auth/access-denied/access-denied.component').then(c=>c.AccessDeniedComponent)},
 
{path:"**",redirectTo:'store',pathMatch:'full',},

  
  ];
  
  
  
