import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './Base.Service';
import { environment } from '../../environments/environment';
import { CryptoService } from './crypto.service';
import { appRoutes } from '../app.routes';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isCollapsed = false;


  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService,
    private crypto: CryptoService,
    private router: Router) {
  }


entities :any[] = [];
permissionGuard :any[] = [];
userPermission :any[] = [];

public getToken(): string {
  return localStorage.getItem('token')!
}

refreshToken (): Observable<any>
{
  return this.baseService.post('Auth/RefreshToken', {}).pipe(
    map(result => {

      if (result.header.success) {
        localStorage.setItem('token', result.userJWT);
        localStorage.setItem('userInfo', JSON.stringify(result.userInfo));
        localStorage.setItem('permissions', this.crypto.set(JSON.stringify(result.permissions)));
        localStorage.removeItem('userPermission');
        localStorage.removeItem('permissionGuard');
      }
      else {
        this.router.navigate(["/login"]);
      }
    }));
}

isUserLogedIn() :boolean {
  let isLoged = false;
  let token = localStorage.getItem('token');
  let entities = localStorage.getItem('permissions');

  if (token != null && entities!=null) {
    isLoged = true;
  }
  return isLoged;
}

getEntities() {

  try{return JSON.parse(this.crypto.get(localStorage.getItem('permissions')!)!)}
  catch{return null}
}

getStoredUserPermissions() {

  try{return JSON.parse(this.crypto.get(localStorage.getItem('userPermission')!)!)}
  catch{return null}
}

getStoredUserPermissionGuard() {

  try{return JSON.parse(this.crypto.get(localStorage.getItem('permissionGuard')!)!)}
  catch{return null}
}

getRefernceId() {
  return JSON.parse(localStorage.getItem('userInfo')!)?.referenceId
}

getUniversityId() {
  return JSON.parse(localStorage.getItem('userInfo')!)?.universityId
}

isSuperAdmin() : boolean {
  let userInfo = JSON.parse(localStorage.getItem('userInfo')!);
  if (userInfo != null) {
    let roleId = this.crypto.decrypt(userInfo['roleId']);
    if (roleId === environment.Roles.Administrator)
      return true
  }
  return false;
}

getPermissionGuard() {
  this.userPermission = []
  this.permissionGuard = []

  let guard=this.getStoredUserPermissionGuard();
  if(guard!=null)
    return guard

this.entities = this.getEntities();

this.permissions.forEach(entity => {

 let indx=this.entities.findIndex((item:any) => item.code === entity.code)
 
 if ( indx > -1) {
   entity.permissionLevel.forEach((level:any) => {
     if (this.entities[indx][level.id]) {
       this.permissionGuard = this.permissionGuard.concat(level.route)
       level.hasPermission = true;
     }
     else
     {
       level.hasPermission = false;
     }
   })
   this.userPermission.push(entity)

 }
})
  localStorage.setItem("userPermission",this.crypto.set(JSON.stringify(this.userPermission)))
  localStorage.setItem("permissionGuard",this.crypto.set(JSON.stringify(this.permissionGuard)))
  return this.permissionGuard;

}


getUserPermission() {

  this.userPermission = []
  this.permissionGuard = []
 
  this.entities = this.getEntities();

  this.permissions.forEach(entity => {
    let indx=this.entities.findIndex((item:any) => item.code === entity.code)
    if ( indx > -1) {
      entity.permissionLevel.forEach((level:any) => {
        if (this.entities[indx][level.id]) {
          this.permissionGuard = this.permissionGuard.concat(level.route)
          level.hasPermission = true;
        }
        else
        {
          level.hasPermission = false;
        }
      })
      this.userPermission.push(entity)

    }
  })

  localStorage.setItem("userPermission",this.crypto.set(JSON.stringify(this.userPermission)))
  localStorage.setItem("permissionGuard",this.crypto.set(JSON.stringify(this.permissionGuard)))
  return this.userPermission;

}

getUserEntityPermission(EntityCode: string) {

  let _Permission=this.getStoredUserPermissions();
  if(_Permission==null)
    _Permission = this.getUserPermission()

  let userPermission = new UserPermisionLevels()
  if (this.isSuperAdmin()) {
    userPermission.add = true;
    userPermission.edit = true;
    userPermission.delete = true;
    userPermission.active = true;
    userPermission.list = true;
    userPermission.view = true;
    userPermission.report = true;

    return userPermission;
  }

  let ind = _Permission.findIndex((item:any) => item.code === EntityCode);
  if (ind > -1) {
    userPermission.add = _Permission[ind].permissionLevel.find((x:any)=>x.id==this.PermissionLevels.Add).hasPermission;
    userPermission.edit = _Permission[ind].permissionLevel.find((x:any)=>x.id==this.PermissionLevels.Edit).hasPermission;
    userPermission.delete = _Permission[ind].permissionLevel.find((x:any)=>x.id==this.PermissionLevels.Delete).hasPermission;
    userPermission.active = _Permission[ind].permissionLevel.find((x:any)=>x.id==this.PermissionLevels.Active).hasPermission;
    userPermission.list = _Permission[ind].permissionLevel.find((x:any)=>x.id==this.PermissionLevels.List).hasPermission;
    userPermission.view = _Permission[ind].permissionLevel.find((x:any)=>x.id==this.PermissionLevels.View).hasPermission;
    userPermission.report = _Permission[ind].permissionLevel.find((x:any)=>x.id==this.PermissionLevels.Report).hasPermission;

  }

  return userPermission;
}

hasPermission(routerLink:any) :boolean {
  const permissionGuard = this.getPermissionGuard();
  if(permissionGuard.includes(routerLink)) return true; else return false;
}

hasAddPermission(routerLink:any) :boolean {
  const permissionGuard = this.getPermissionGuard();
  let allow = false;
  if (permissionGuard.includes(routerLink.replace("list", "add"))) {
    allow = true;
  }
  return allow;
}

isEditOrList(routerLink:any) :boolean {
  const permissionGuard = this.getPermissionGuard();
  let allow = false;
  permissionGuard.forEach((url:string) => {
      if (routerLink.indexOf(url) == 0) { allow = true; return; }
  });

  return allow;
}

isNotHasPermission(routerLink:any) :boolean {
  let hasPer = false;
  this.entities = this.getEntities();

  this.permissions.forEach(entity => {
    entity.permissionLevel.forEach(level => {
      if (level.route.includes(routerLink)) {
        hasPer = true; return;
      }
    })
  })
  return hasPer;
}


EntitiesCodes = environment.EntitiesCodes;

PermissionLevels = {Add:"add",Edit:"edit",Delete:"delete",Active:"active",List:"list",View:"view",Report:"report"};


permissions = [
  {
    code: this.EntitiesCodes.user,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addUser}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editUser}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listUser}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editUser}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      }
    ]

  },
  {
    code: this.EntitiesCodes.category,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addCategory}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editCategory}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listCategory}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editCategory}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      }
    ]

  },

  {
    code: this.EntitiesCodes.role,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addRole}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editRole}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listRole}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editRole}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      }
    ]

  },

  {
    code: this.EntitiesCodes.student,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addStudent}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editStudent}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listStudent}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editStudent}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      }
    ]

  },


  {
    code: this.EntitiesCodes.employee,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addEmployee}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editEmployee}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listEmployee}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editEmployee}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      }
    ]

  },



  {
    code: this.EntitiesCodes.teacher,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addTeacher}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editTeacher}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listTeacher}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editTeacher}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      }
    ]

  },

  {
    code: this.EntitiesCodes.product,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addProduct}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editProduct}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listProduct}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editProduct}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      }
    ]

  },

  
  {
    code: this.EntitiesCodes.tag,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addTag}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editTag}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listTag}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editTag}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      },
      
    ]

  },
  


  {
    code: this.EntitiesCodes.reference,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addReference}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editReference}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listReference}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editReference}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      },
    ]
  },

  {
    code: this.EntitiesCodes.position,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addPosition}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editPosition}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listPosition}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editPosition}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      },
    ]
  },
  {
    code: this.EntitiesCodes.order,
    permissionLevel: [
      {
        id: this.PermissionLevels.Add,
        route: [`/${appRoutes.addOrder}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Edit,
        route: [`/${appRoutes.editOrder}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Delete,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Active,
        route: [],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.List,
        route: [`/${appRoutes.listOrder}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.View,
        route: [`/${appRoutes.editOrder}`],
        hasPermission: false
      },
      {
        id: this.PermissionLevels.Report,
        route: [],
        hasPermission: false
      },
    ]
  },
]
}

export class UserPermisionLevels {
  constructor() {
    this.add = false
    this.edit = false
    this.delete = false
    this.active = false
    this.list = false
    this.view = false
    this.report = false
  }
  add: boolean
  edit: boolean
  delete: boolean
  list: boolean
  view: boolean
  active: boolean
  report: boolean
}

